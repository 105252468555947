class LightBox {
	constructor(node) {
		this.node = node;
		this.photo = this.node.querySelector(".dyn-fc-image");
		this.name = this.node.querySelector(".dyn-fc-name");
		this.position = this.node.querySelector(".dyn-fc-position");
		this.excerpt = this.node.querySelector(".dyn-fc-excerpt");
	}
	clean() {
		this.photo.src = "";
		this.name.innerHTML = "";
		this.position.innerHTML = "";
		this.excerpt.innerHTML = "";
	}
	open({ name, position, excerpt, photo }) {
		this.node.classList.add("active");
		this.clean();
		this.photo.src = photo;
		this.name.innerHTML = name;
		this.position.innerHTML = position;
		this.excerpt.innerHTML = excerpt;
	}
}

class Team {
	constructor(node) {
		this.node = node;
		this.lightbox = null;
		this.items = this.node.querySelectorAll(".team-grid-item");
	}
	handleClick(e) {
		const { currentTarget } = e;

		this.items.forEach(item => {
			item.classList.remove("active");
		});
		currentTarget.classList.add("active");
		console.log("currentTarget.dataset.img:", currentTarget.dataset.img);
		this.lightbox.open({
			photo: currentTarget.dataset.img,
			name: currentTarget.dataset.name,
			position: currentTarget.dataset.position,
			excerpt: currentTarget.querySelector(".excerpt").innerHTML
		});
	}
	init() {
		const cardFixed = document.querySelector(".fixed-card-team");
		console.log(cardFixed);
		if (cardFixed) {
			this.lightbox = new LightBox(cardFixed);
			console.log(this.lightbox);
		}
		if (this.items.length) {
			this.items.forEach(item => {
				item.addEventListener("click", this.handleClick.bind(this));
			});
		}
	}
}

export default Team;
