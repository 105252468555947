export default {
	init() {
		const eventDayAccordion = document.querySelectorAll(".event-day-accordion");
		eventDayAccordion.forEach((accordion) => {
			const buttonClosedText = "close";
			const buttonOpenText = "open";
			const accordionButton = accordion.querySelector(
				".event-day-accordion__button"
			);
			if (!accordionButton) {
				console.error("no accordion button found at ", accordion);
				return;
			}
			accordionButton.addEventListener("click", () => {
				accordion.classList.toggle("event-day-accordion--active");
				if (window.innerWidth < 1024) {
					return;
				}
				if (accordionButton.innerHTML === buttonClosedText) {
					accordionButton.innerHTML = buttonOpenText;
				} else {
					accordionButton.innerHTML = buttonClosedText;
				}
			});
			function accordionMobileActions() {
				console.log(window.innerWidth);
				if (window.innerWidth < 1024) {
					accordion.classList.remove("event-day-accordion--active");
					accordionButton.innerHTML = "";
				} else {
					accordion.classList.add("event-day-accordion--active");
					accordionButton.innerHTML = buttonClosedText;
				}
			}
			window.addEventListener("resize", accordionMobileActions);
			window.addEventListener("load", accordionMobileActions);
			const eventItems = accordion.querySelectorAll(".event-item");
			if (!eventItems) {
				console.error("no event items found at ", accordion);
				return;
			}

			eventItems.forEach((eventItem) => {
				const eventItemButton = eventItem.querySelector(".event-item__button");

				if (!eventItemButton) {
					console.error("no event button found at ", eventItem);
					return;
				}
				eventItemButton.addEventListener("click", (e) => {
					e.preventDefault();
					eventItem.classList.toggle("event-item--active");

					if (eventItemButton.innerHTML === "-") {
						eventItemButton.innerHTML = "+";
					} else {
						eventItemButton.innerHTML = "-";
					}
				});
			});
		});
	},
};
