export default function stickyNav() {
	if ($(".stickyNav-linksMobile select").length) {
		console.log('exists');
		$(".stickyNav-linksMobile select").change(function(event) {
			var link = $(this).find(":selected").attr("data-href");

			console.log(link);

			// has a 'data-href' attr
			if (link) {
				if (/^#/.test(link)) {
					console.log('test');
					var anchor = link.substring(1);
					var $e = $('[data-anchor="' + anchor + '"]');

					if ($e.length && $('body').hasClass('has-top-banner')) {
						$("html, body").stop().animate({ scrollTop: ($e.offset().top - 205) }, 600);
					} else if ($e.length) {
						$("html, body").stop().animate({ scrollTop: ($e.offset().top - 95) }, 600);
					}
				} else {
					var target = $(this).find(":selected").attr("data-target");

					if (target == "_blank") {
						window.open(link, "_blank").focus();
					} else {
						window.location.href = link;
					}
				}
			}

		});
	}
	// window.location.href;

	if ($(".stickyNav").length) {
		// STICKY TABS
		var stickyNavObserver = new IntersectionObserver(
			function(entries) {
				// No intersection
				// skip for page-type sticky navs (like on the training detail pages)
				entries.forEach(entry => {
					// console.log(entry.target.getAttribute("data-anchor") + ' : ' + entry.intersectionRatio);
					// if (entry.intersectionRatio <= 0.15) {
					// 	entry.target.classList.remove("active");
					// 	document.querySelector(`.stickyNav-link a[href="#${entry.target.getAttribute("data-anchor")}"]`).classList.remove("active");
					// }

					// // Fully intersects
					// if (entry.intersectionRatio > 0.15) {
					// 	// console.log(entry.target);
					// 	entry.target.classList.add("active");
					// 	document.querySelector(`.stickyNav-link a[href="#${entry.target.getAttribute("data-anchor")}"]`).classList.add("active");
					// }

					// new method
					if (entry.isIntersecting) {
						entry.target.classList.add("active");
						document.querySelector(`.stickyNav-link a[href="#${entry.target.getAttribute("data-anchor")}"]`).classList.add("active");
					} else {
						entry.target.classList.remove("active");
						document.querySelector(`.stickyNav-link a[href="#${entry.target.getAttribute("data-anchor")}"]`).classList.remove("active");
					}
				});
			}, {
		    rootMargin: "-49.9% 0px -49.9% 0px",
		    threshold: [0, 1]
			}
		);

		if (!$(".stickyNav").hasClass("type-page")) {
			$(".stickyNav-link a").each(function(index, el) {
				var link = $(this).attr("href").split("#")[1];
				var target = $('[data-anchor="' + link + '"]');
				let vanillaTarget = '[data-anchor="' + link + '"]';
				// if (link) {
				// 	console.log(link);
				// }
				// if (target) {
				// 	console.log(target);
				// }

				if (!target || !link) {
					return;
				}
				stickyNavObserver.observe(document.querySelector(vanillaTarget));
			});
		}
	}

	function checkStickyLinks() {}

	// checkStickyLinks();

	if ($(".stickyNav-link a").length) {
		$(".stickyNav-link a").click(function(event) {
			$(".stickyNav-link a").removeClass("active");
			$(this).addClass("active");
		});
	}
}
