import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
export default function components_init() {
	if ($("[data-href]:not(.href-init)").length) {
		$("[data-href]:not(.href-init)").each(function(i, e) {
			var $e = $(e);
			$e.addClass("href-init");
			$e.mousedown(function(event) {
				$(this).data("clientX", event.originalEvent.clientX);
				$(this).data("clientY", event.originalEvent.clientY);
			});
			$e.click(function(event) {
				var clientX_ =
						typeof event.originalEvent.clientX === "undefined"
							? 0
							: event.originalEvent.clientX,
					clientX = $(this).data("clientX"),
					clientY_ =
						typeof event.originalEvent.clientY === "undefined"
							? 0
							: event.originalEvent.clientY,
					clientY = $(this).data("clientY");
				clientX = typeof clientX === "undefined" ? clientX_ : clientX;
				clientY = typeof clientY === "undefined" ? clientY_ : clientY;
				if (
					Math.abs(clientX - clientX_) < 5 &&
					Math.abs(clientY - clientY_) < 5
				) {
					window.location = $(this).data("href");
				}
			});
		});
	}
	if ($("[data-slick]").length) {
		$("[data-slick]").slick();
	}

	if ($("[data-progress-accordion]").length) {
		$("[data-progress-accordion]").each(function(i, e) {
			var o = {
				$e: $(e),
				$items: $(e).find("[data-progress]"),
				miliseconds: $(e).data("progress-accordion"),
				current: 0,
				interval: false,
				set_current_progress: function(progress) {
					for (var i = 0; i < this.$items.length; i++) {
						var $_item = this.$items.eq(i);
						if (i < this.current) {
							$_item.attr("data-progress", 0);
							$_item.data("progress", 0);
						} else if (i === this.current) {
							$_item.attr("data-progress", progress);
							$_item.data("progress", progress);
						} else {
							$_item.attr("data-progress", 0);
							$_item.data("progress", 0);
						}
					}
				},
				get_current_progress: function(progress) {
					return parseInt(this.$items.eq(this.current).data("progress"));
				},
				set_current: function(index) {
					this.current = Math.max(0, index >= this.$items.length ? 0 : index);
					this.$items.removeClass("current");
					this.$items
						.eq(this.current)
						.addClass("current")
						.find(".collapse")
						.collapse("show");
				},
				tick: function() {
					var progress = this.get_current_progress() + 1;
					if (progress > 100) {
						this.set_current(this.current + 1);
						progress = 0;
					}
					this.set_current_progress(progress);
				},
				start: function() {
					if (!this.interval) {
						var _this = this;
						this.interval = setInterval(function() {
							_this.tick();
						}, this.miliseconds / 100);
					}
				},
				pause: function() {
					if (this.interval) {
						clearInterval(this.interval);
						this.interval = false;
					}
				},
				init: function() {
					var _this = this,
						$controls = this.$e.find('[data-toggle="collapse"]');
					$controls.click(function(event) {
						if (!$(this).hasClass("collapsed")) {
							event.stopPropagation();
						} else {
							_this.pause();
							_this.set_current(
								$(this)
									.parents("[data-progress]")
									.index()
							);
							_this.set_current_progress(0);
							setTimeout(function() {
								_this.start();
							}, 5000);
						}
					});
					this.$items.hover(
						function() {
							if ($(this).index() == _this.current) {
								_this.pause();
							}
						},
						function() {
							if ($(this).index() == _this.current) {
								_this.start();
							}
						}
					);
					//this.$e.css('--transition-duration', this.miliseconds / 10 + 'ms');
					this.start();
				}
			};
			o.init();
			o.$e.data("progress-accordion-object", o);
		});
	}

	if ($("[data-event-class]").length) {
		$("[data-event-class]").each(function(i, e) {
			var event_class = $(e)
				.data("event-class")
				.split("|");
			if (event_class.length === 2) {
				$(".so-collapse__item").on("show.bs.collapse", function(e) {
					$(e.currentTarget).addClass("active");
				});
				$(".so-collapse__item").on("hide.bs.collapse", function(e) {
					$(e.currentTarget).removeClass("active");
				});
			} else if (event_class.length === 1) {
			}
		});
	}

	if ($(".so-flip-card__trigger").length) {
		$(".so-flip-card__trigger").click(function(event) {
			event.preventDefault();
			$(this)
				.parents(".so-flip-card")
				.toggleClass("so-flip-card--flip");
		});
	}

	if ($("[data-parallax]").length) {
		$("[data-parallax]").each(function(i, e) {
			gsap.to(e, {
				yPercent: $(e).data("parallax"),
				ease: "none",
				scrollTrigger: {
					trigger: e,
					start: "top bottom",
					end: "bottom top",
					scrub: true
				}
			});
		});
	}
	if ($("[data-layers]").length) {
		$("[data-layers]").each(function(i, e) {
			var $e = $(e),
				o = {
					$element: $e,
					settings: $.extend(
						{},
						{
							auto: true,
							infinite: false,
							delay: 1000,
							restart: 0
						},
						$e.data("layers-settings")
					),
					total: $e.children().length,
					interval: false,
					init: function() {
						if (this.settings.auto) {
							this.start();
						}
					},
					get_current: function() {
						return Math.max(0, parseInt(this.$element.data("layers")));
					},
					next: function() {
						var current = this.get_current(),
							next = current + 1;
						if (next > this.total) {
							next = this.settings.infinite
								? this.settings.restart
								: this.total;
						}
						if (current != next) {
							this.$element.data("layers", next);
							this.$element.attr("data-layers", next);
							this.start();
						}
					},
					start: function() {
						var _this = this,
							delay = this.$element
								.children()
								.eq(this.get_current() - 1)
								.data("layers-delay");
						delay = delay !== null && delay >= 0 ? delay : this.settings.delay;
						this.interval = setTimeout(function() {
							_this.next();
						}, delay);
					},
					pause: function() {
						if (this.interval) {
							clearInterval(this.interval);
							this.interval = false;
						}
					}
				};
			o.init();
			$e.data("layers-object", o);
		});
	}

	if ($('[data-toggle="tooltip"]').length) {
		$('[data-toggle="tooltip"]').tooltip();
	}

	if ($("[data-toggle-class]").length) {
		$("[data-toggle-class]").click(function(event) {
			event.preventDefault();
			var t_clsses = $(this)
				.data("toggle-class")
				.split("&");
			for (
				var i = 0, toggle = undefined, t_class, $object;
				i < t_clsses.length;
				i++
			) {
				t_class = t_clsses[i].split("|");
				$object = t_class.length > 1 ? $(this).parents(t_class[1]) : $(this);
				if (t_class.length > 2) {
					$object = $object.find(t_class[2]);
				}
				if (toggle === undefined) {
					toggle = !$object.hasClass(t_class[0]);
				}
				$object.toggleClass(t_class[0], toggle);
			}
		});
	}

	if ($("[data-animated-counter]").length) {
		$("[data-animated-counter]").each(function(i, e) {
			var o = {
				$e: $(e),
				from: 0,
				to: $(e).data("animated-counter"),
				interval: false,
				get_value: function() {
					return parseInt(this.$e.text());
				},
				set_value: function(value) {
					return this.$e.text(value);
				},
				tick: function() {
					var value = this.get_value();
					if (value >= this.to) {
						this.pause();
					} else {
						this.set_value(Math.min(this.to, value + Math.ceil(this.to / 20)));
					}
				},
				start: function() {
					if (!this.interval) {
						var _this = this;
						this.interval = setInterval(function() {
							_this.tick();
						}, 80);
					}
				},
				pause: function() {
					if (this.interval) {
						clearInterval(this.interval);
						this.interval = false;
					}
				},
				init: function() {
					var _this = this;
					this.set_value(this.from);
					$(window).on("resize scroll", function(event) {
						if (_this.$e.isInViewport()) {
							_this.start();
							$(this).off(event);
						}
					});
				}
			};
			o.init();
			o.$e.data("counter-object", o);
		});
	}

	if ($("[data-filter-control]").length) {
		$("[data-filter-control]").click(function() {
			var filter = $(this)
					.data("filter-control")
					.split("|"),
				$items = $(this)
					.parents(filter[1])
					.find("[data-filter-item]"),
				$parent = $items.first().parent(),
				$show =
					filter[0] === "*"
						? $items
						: $items.filter('[data-filter-item="' + filter[0] + '"]'),
				$hide =
					filter[0] === "*"
						? $([])
						: $items.not('[data-filter-item="' + filter[0] + '"]');

			$parent.stop(true).fadeOut(function() {
				$show.show();
				$hide.hide();
				$parent.stop(true).fadeIn();
			});

			$(this)
				.parents(filter[1])
				.find("[data-filter-control]")
				.removeClass("active");
			$(this).addClass("active");
		});
	}

	if ($("[data-ajax-template]").length) {
		$("[data-ajax-template]").each(function(i, e) {
			var o = {
				$e: $(e),
				init: function() {
					this.get_data(this.$e.data("ajax-template"));
				},
				get_data: function(options) {
					var _this = this;
					$.get(
						options.url,
						function(data) {
							_this.$e.fadeOut("fast", function() {
								_this.$e.empty();
								_this.$e.append(_this.generate_template(data, options));
								_this.$e.fadeIn();
							});
						},
						"json"
					).always(function() {
						_this.$e.removeClass("loading");
					});
				},
				generate_template(data, options) {
					var template = "";
					if (Array.isArray(data)) {
						for (var i = 0; i < data.length; i++) {
							template += this.generate_template(data[i], options);
						}
					} else {
						var _template = $(options.template).html();
						for (var prop in data) {
							_template = _template.replace(
								"%" + prop + "%",
								typeof options.children !== "undefined" &&
									typeof options.children[prop] !== "undefined"
									? this.generate_template(data[prop], options.children[prop])
									: data[prop]
							);
						}
						template += _template;
					}
					return template;
				}
			};
			o.init();
			$(e).data("ajax-template-object", o);
		});
	}
}
