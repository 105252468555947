$("#pagination").on("click", "a", function(e) {
	e.preventDefault();
	$(this).remove();
	$("#ajax-loader").load($(this).attr("href") + " #ajax-posts>*", function() {
		$("#ajax-posts").append($("#ajax-loader").html());
	});
	$("#pagination").load($(this).attr("href") + " #pagination>*");
});

$(".pagenavi-container").on("click", ".wp-pagenavi>a", function(e) {
	e.preventDefault();

	var link = $(this).attr("href"),
		this_instance = $(this)
			.parents(".pagination")
			.data("instance"),
		$this_instance = $(
			".pagenavi-container[data-instance=" + this_instance + "]"
		),
		$post_grid = $("#post-grid"),
		post_grid = "#post-grid",
		$this_pagination = $(this).parents(".wp-pagenavi");

	// if this pagination corresponds to a specific post grid
	if (this_instance) {
		$post_grid = $('#post-grid[data-instance="' + this_instance + '"]');
		post_grid = '#post-grid[data-instance="' + this_instance + '"]';
	}

	// load posts into grid
	$post_grid.load(link + " " + post_grid + ">*");

	// load new pagination
	$this_pagination.load(
		link +
			" .pagenavi-container[data-instance=" +
			this_instance +
			"] .wp-pagenavi>*"
	);
});
