export default function teamGrid() {
	// TEAM
	const $teamGrid = $(".team-grid");
	if ($teamGrid.length) {
		const $teamGridItems = $teamGrid.find(".team-grid-item");
		const $select = $("select.so-tabs-mobileSelect");
		$select.on("change", function(event) {
			const $this = $(this);
			$this.parent().toggleClass("is-open");
			const filter = $this
				.find(":selected")
				.attr("data-filter-control")
				.replace("|.so-section", "");

			if (filter === "*") {
				$teamGridItems.show();
			} else {
				$teamGridItems.hide();
				$teamGridItems.filter('[data-filter-item="' + filter + '"]').show();
				$("html, body").animate(
					{
						scrollTop: $teamGrid.offset().top - 160
					},
					600
				);
			}
		});
	}
}
