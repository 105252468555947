export default function soTabsStickyTop() {
	if ($(".so-tabs-sticky-top").length) {
		// STICKY TABS
		var observer = new IntersectionObserver(
			function(entries) {
				// No intersection
				if (entries[0].intersectionRatio === 0) {
					document
						.querySelector(".so-tabs-sticky--desktop")
						.classList.add("is-stuck");
					document
						.querySelector(".so-tabs-sticky--mobile")
						.classList.add("is-stuck");
				}
				// Fully intersects
				else if (entries[0].intersectionRatio === 1) {
					document
						.querySelector(".so-tabs-sticky--desktop")
						.classList.remove("is-stuck");
					document
						.querySelector(".so-tabs-sticky--mobile")
						.classList.remove("is-stuck");
				}
			},
			{
				threshold: [0, 1],
				rootMargin: "-80px 0px 0px 0px"
			}
		);
		observer.observe(document.querySelector(".so-tabs-sticky-top"));
	}
}
