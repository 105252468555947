export default {
	init(){
		$('#popup-form').hide();

		$('#form-modal').on('click', function(e){
			e.preventDefault();
			$('#popup-form').addClass('active').fadeIn();
		})


		$('#close-form').on('click', function(e){
			e.preventDefault();
			$('#popup-form').removeClass('active').fadeOut();
		})
	}
}
