export default function sliders() {
	if ($(".event-list-slider").length) {
		$(".event-list-slider").slick({
			arrows: true,
			dots: false,
			speed: 300,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
			infinite: false,
			loop: false,
			// adaptiveHeight: true,
			prevArrow: "#events-prev",
			nextArrow: "#events-next",
			rows: 4,
			slidesPerRow: 1
		});
	}

	if ($(".testimonials-block-slider").length) {
		$(".testimonials-block-slider").slick({
			arrows: true,
			dots: false,
			speed: 300,
			autoplay: false,
			infinite: true,
			adaptiveHeight: true,
			prevArrow: "#testimonials-prev",
			nextArrow: "#testimonials-next",
			mobileFirst: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 767,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
					}
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
					}
				}
			]
		});
	}

  if ($(".testimonialsColumns-block-slider").length) {
    $(".testimonialsColumns-block-slider").slick({
      arrows: true,
      dots: false,
      speed: 300,
      autoplay: false,
      infinite: true,
      adaptiveHeight: true,
      prevArrow: "#testimonialsColumns-prev",
      nextArrow: "#testimonialsColumns-next",
      mobileFirst: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    });
  }

	if ($(".animated-hero__events-ticker .so-section__col").length) {
		$(".animated-hero__events-ticker .so-section__col").slick({
			arrows: false,
			dots: false,
			speed: 1500,
			autoplaySpeed: 6000,
			// verticalSwiping: true,
			// vertical: true,
			autoplay: true,
			infinite: true,
			// adaptiveHeight: true,
			mobileFirst: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			centerMode: false,
			fade: true
		});
	}

	if ($('.section-slider .slider-wrapper').length) {
		$('.section-slider .slider-wrapper').each(function(i) {
			let thisID = $(this).data('id'),
					autoPlay = true;

			if ($(this).hasClass('no-auto-play')) {
				autoPlay = false;
			}

			$(this).slick({
				arrows: true,
				dots: true,
				speed: 300,
				autoplay: autoPlay,
				infinite: true,
				adaptiveHeight: true,
				prevArrow: $('#slider-navigation-' + thisID + ' .slider-button-prev'),
				nextArrow: $('#slider-navigation-' + thisID + ' .slider-button-next'),
				appendDots: $('#slider-navigation-' + thisID + ' .slider-pagination'),
				mobileFirst: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				fade: true
			});
		})
	}

	// $(".animated-hero__events-ticker .so-section__row").slick({
	// 	arrows: false,
	// 	dots: false,
	// 	speed: 1000,
	// 	autoplay: false,
	// 	verticalSwiping: true,
	// 	vertical: true,
	// 	infinite: true,
	// 	slidesToShow: 1,
	// 	slidesToScroll: 1
	// });
}
