require("slick-carousel");
require("bootstrap");

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import AOS from "aos";
import stickyNav from "../modules/stickyNav";
import soTabsStickyTop from "../modules/soTabsStickyTop";
import teamGrid from "../modules/teamGrid";
import soTabsItem from "../modules/soTabsItem";
import eventDates from "../modules/eventDates";
import components_init from "../modules/initComponents";
import nav from "../modules/navigation";
import sliders from "../modules/sliders";
import eventsAccordion from "../modules/eventsAccordion";
import eventPage from "../modules/eventPage";
import countdownStrip from "../modules/countdownStrip";
import imageTextColumns from "../modules/imageTextColumns";
import lottieWeb from "lottie-web";
import {create} from '@lottiefiles/lottie-interactivity';
export default {
	init() {
		console.log("Nylon | Route: Common");


    // TOP BANNER

    function getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    $(".close-banner").on("click", function (e) {
      e.preventDefault();
      var cookie = $(this).data("cookieID");
      document.cookie = "visited=" + cookie;
      var CookieDate = new Date();
      CookieDate.setFullYear(CookieDate.getFullYear() + 10);
      var cookie = $(this).data("cookieid");
      document.cookie = cookie + "=visited; expires=" + CookieDate.toUTCString() + "; path=/;";

      $("#top-banner").hide();

      $(".has-top-banner .so-header, .has-top-banner .so-main, .push-top-banner").animate({
        marginTop: 0,
      });
      $(".has-top-banner .on-page-sticky-nav").animate({
      	top: 0,
      });
      $(".has-top-banner .stickyNav").animate({
      	top: 80,
      });
    });

    // STICKY NAV

		stickyNav();
		soTabsStickyTop();
		teamGrid();
		soTabsItem();
		eventDates();
		eventsAccordion.init();
		eventPage.init();
		countdownStrip();
		imageTextColumns();
		// refresh aos after any ajax request
		// commenting this out unless we end up needing it
		$(document).ajaxComplete(function () {
			console.log("ajaxComplete");
			AOS.refresh();
		});

		gsap.registerPlugin(ScrollTrigger);

		$.fn.isInViewport = function () {
			var t = $(this).offset().top,
				b = t + $(this).outerHeight(),
				wt = $(window).scrollTop(),
				wb = wt + $(window).height();
			return b > wt && t < wb;
		};

		nav.init();
		components_init();
		sliders();

		AOS.init({
			duration: 800,
			easing: "ease-out-cubic",
			offset: 50,
			once: true,
			disable: "mobile"
		});


/* Animate words on home hero */
jQuery(function($){
    if ($('.animated-word').length){
        var words = $('.words-container .word');
        if (words.length > 1){
            var len = words.length;
            var ix = 2;

            var int = setInterval(function(){
                if (ix == len){
                    ix = 1;
                }
                var wordclass = ".w" + ix;
                $('.word.active').removeClass('active');
                //setTimeout(function(){
                    $(wordclass).addClass('active');
                //}, 500);
                ix++;
            }, 4000);


        }
    }
});

/* Animate Lotties */

jQuery(function($){
    function playLottie(id, src){
        let animation = bodymovin.loadAnimation({
            container: div,
            renderer: "svg",
            loop: false,
            autoplay: false,
            path: src,
        });
        animation.play();
    }
});


		$(document).ready(function(){
			// $('.tooltip-trigger').on('click', function(e){
			// 	e.preventDefault();
			// 	$('.tooltip-area').removeClass('active');
			// 	var trigger = $(this).data('trigger');

			// 	if($(trigger).hasClass('active')){ 
			// 		$(trigger).removeClass('active');
			// 		alert();
			// 	} else {
			// 		$(trigger).addClass('active');
			// 	};
			// })

      /* Check if this page has
          1) Anchor modules, and
          2) the Sidebar-Nav global module checked as true
      */
      var anchors = $('section.anchor'),
       		sidebar = $('section.sidebar-nav'),
       		sidebarTrigger = $('.sidebar-nav-container .sidebar-hover-trigger');

      if (sidebar.length > 0 && anchors.length > 0){
        // We have both, so set up the sidebar functionality

        // Show the sidebar-nav on hover
        sidebarTrigger.on({
          mouseenter: function (event) {
            if (! $(this).closest('.sidebar-nav').hasClass('visible')){
              $(this).closest('.sidebar-nav').addClass('visible');
            }
          }
        });

        sidebar.on({
        	mouseleave: function (event) {
        		$(this).removeClass('visible');
        	}
        })

        var anchor_offsets = getAnchorOffsets(anchors),
        		indexToUpdate = -1,
        		sidebarLinks = $(sidebar).find('a');

        $(window).on('scroll', function() {
          updateAnchors();
        });

        $(window).on('resize', function() {
          anchor_offsets = getAnchorOffsets(anchors);
        });

        /* updateAnchors()
          Compares the screen position on the Y axis to all of the anchor modules. Updates the sidebar-nav list with an 'active' class to the corresponding anchor the viewer is seeing
        */
        function updateAnchors(){
          var scrollTop = $(window).scrollTop() + 150; // need a little extra offset for the top of the page
          var lastIndexUpdate = indexToUpdate;
          //console.log("Last index update is " + lastIndexUpdate);
          $(anchor_offsets).each(function(index){
            var anchor_top = $(this)[0];
            if (scrollTop > anchor_top){
              indexToUpdate = index;
            }
          });
          //console.log("the " + indexToUpdate + " should be the one we change");
          //console.log("scrolltop is " + scrollTop);
          //console.log("first is " + $(anchor_offsets)[0]);
          //console.log("second is " + $(anchor_offsets)[1]);
          if (indexToUpdate >= 0 && lastIndexUpdate != indexToUpdate){
            if (lastIndexUpdate >= 0){
              $(sidebarLinks).eq(lastIndexUpdate).find('li').removeClass('active');
            }
            $(sidebarLinks).eq(indexToUpdate).find('li').addClass('active');
          }
        }

        /* getAnchorOffsets(anchor_array)
          loop through the anchors (taken above) and get their offsets
          return array of offsets
        */
        function getAnchorOffsets(anchor_array){
          var anchor_offsets = [];
          $(anchor_array).each(function(){
            anchor_offsets.push($(this).offset().top);
          });
          return anchor_offsets;
          }
       }

			$('.tooltip-trigger').hover(function(e){
				e.preventDefault();
				var trigger = $(this).data('trigger');
				$(trigger).addClass('active');
			}, function(){
				$('.tooltip-area').removeClass('active');
			})

			$('.read-bio').on('click', function(e){
				e.preventDefault();

				var target = $(this).data('target');
				$(target).toggleClass('active');
			})

			// speakers module
			$('.read-bio-speaker').on('click', function(e){
				e.preventDefault();

				// open the modal
				$('.speaker-modal').toggleClass('active');
				// show the info for the specific speaker
				var target = $(this).data('target');
				$(target).toggleClass('active');
			})

			$('.speaker-modal .so-btn').on('click', function(e){
				$('.speaker-modal-bio.active').toggleClass('active');
			})
		})

		$('.on-page-sticky-nav .mobile-toggler').on('click', function(e){
			$('.on-page-sticky-nav .sticky-nav').slideToggle('200');
		})

	},
	finalize() {},
};
