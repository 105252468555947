export default function eventDates() {
	if ($(".event-date").length) {
		// EVENTS
		$(".event-date").each(function(index, el) {
			var text = $(this).html();
			console.log(text);
			var newText = text.replace("|", "&nbsp;|&nbsp;");

			$(this).html(newText);
		});
	}
}
