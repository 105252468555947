import Router from "./router";
import common from "./routes/common";
import Team from "./modules/team";
import "./modules/pagination";
import "./modules/open-accordion-on-nav";
import AOS from "aos";

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
	common
});

/** Load Events */
$(() => {
	AOS.init({
		duration: 800,
		easing: "ease-out-cubic",
		offset: 50,
		once: true,
		disable: "mobile"
	});

	routes.loadEvents();

	const teamNode = document.querySelector(".team-grid");
	if (teamNode) {
		const team = new Team(teamNode);
		team.init();
	}
});
