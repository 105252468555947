export default function soTabsItem() {
	if ($(".so-tabs__item").length) {
		// SERVICES
		$(".so-tabs__item").click(function(event) {
			if (
				$(this)
					.closest(".so-section")
					.hasClass("so-section-our-services")
			) {
				var filter = $(this)
					.data("filter-control")
					.split("|")[0];
				$(".our-services-image div").hide();
				$(this)
					.closest(".so-section")
					.find('.our-services-image div[data-filter-item="' + filter + '"]')
					.show();
			} else if (
				$(this)
					.closest(".so-section")
					.hasClass("so-section-assessments")
			) {
				var filter = $(this)
					.data("filter-control")
					.split("|")[0];
				$(".assessments-img-wrapper div").hide();
				$(this)
					.closest(".so-section")
					.find(
						'.assessments-img-wrapper div[data-filter-item="' + filter + '"]'
					)
					.show();
			}
		});
	}
}
