/**
 * A function to open the accordion when the user clicks on the nav item.
 */

function slugify(text) {
	return text
		.toString()
		.toLowerCase()
		.replace(/\s+/g, "-")
		.replace(/[^\w\-]+/g, "")
		.replace(/\-\-+/g, "-")
		.replace(/^-+/, "")
		.replace(/-+$/, "");
}

$(function () {
	console.log("-- ready custom nav items");
	const menuLinks = $(".open-accordion");
	menuLinks.on("click", (e) => {
		const $this = $(e.delegateTarget);
		const name = slugify($this.text());
		console.log("name: ", name);
		const tabItem = $('.so-tabs__item[data-tab="' + name + '"]');
		console.log("tabItem:", tabItem);

		if (tabItem.length) {
			const tabParent = tabItem.closest(".so-tabs");
			console.log("tabParent:", tabParent);
			e.preventDefault();
			tabItem.trigger("click");
			// scroll to tabs container
			$("html, body").animate(
				{
					scrollTop:
						tabParent.offset().top - $(".so-header").outerHeight() * 1.2,
				},
				1000
			);
		}
	});
	$(window).on("load hashchange onhashchange", () => {
		const hash = window.location.hash;
		// console.log("hash: ", hash);
		if (window.location.hash === undefined || window.location.hash === "") {
			// console.log("no hash provided");
			return;
		}
		const tabItem = $(hash + ".so-tabs__item[data-tab]");
		if (tabItem.length < 1) {
			// console.log("no tabs founds");
			return;
		}
		tabItem.trigger("click");
	});
});
