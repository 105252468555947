const nav = {
	items: {
		$trigger: $("#nav-trigger")
	},
	classes: ["nav"],
	scrollTop: 0,
	update: function() {
		this.items.$trigger.toggleClass("is-active", this.nav_opened());
	},
	nav_opened: function() {
		return $("html").hasClass("nav-open");
	},
	toggle_nav: function(class_name, toggle) {
		for (var i = this.classes.length - 1; i >= 0; i--) {
			if (this.classes[i] !== class_name) {
				$("html").removeClass(this.classes[i] + "-open");
			}
		}
		$("html").toggleClass(class_name + "-open", toggle);
		this.update();
	},
	scroll: function(scrollTop) {
		if (scrollTop !== this.scrollTop) {
			$("html").toggleClass("page-scrolled", scrollTop > 50);
			this.scrollTop = scrollTop;
		}
	},
	scroll_to_anchor: function(anchor) {
		// console.log("scroll to anchor: " + anchor);
		var $e = $('[data-anchor="' + anchor + '"]');
		if ($e.length && $('body').hasClass('has-top-banner')) {
			$("html, body").stop().animate({ scrollTop: $e.offset().top - 205 }, 600);
		} else if ($e.length) {
			$("html, body").stop().animate({ scrollTop: $e.offset().top - 95 }, 600);
		}
	},
	init_onhashchange: function() {
		console.log("on hash change");
		if ("onhashchange" in window) {
			return;
		}
		var location = window.location,
			oldURL = location.href,
			oldHash = location.hash;
		setInterval(function() {
			if (
				window.location.hash != oldHash &&
				typeof window.onhashchange === "function"
			) {
				window.onhashchange({
					type: "hashchange",
					oldURL: oldURL,
					newURL: window.location.href
				});
				oldURL = window.location.href;
				oldHash = window.location.hash;
			}
		}, 100);
	},
	init: function() {
		var _this = this,
			scroll = function() {
				_this.scroll($(window).scrollTop());
			};
		this.update();
		this.items.$trigger.click(function() {
			_this.toggle_nav("nav");
		});
		$(window).scroll(scroll);

		scroll();

		//this.init_onhashchange();

		$(window).on("hashchange onhashchange", function(event) {
			if (window.location.hash) {
				_this.scroll_to_anchor(window.location.hash.substr(1));
			}
		});
		$('a[href^="#"]').on("click", function() {
			if (window.location.hash === $(this).attr("href")) {
				_this.scroll_to_anchor(window.location.hash.substr(1));
			}
		});

		if (window.location.hash) {
			setTimeout(function() {
				_this.scroll_to_anchor(window.location.hash.substr(1));
			}, 768);
			//_this.scroll_to_anchor(window.location.hash.substr(1));
		}
	}
};

export default nav;
