export default function countdownStrip() {
	var dateValuesEl = document.getElementById('countdown-strip__values');

	if (dateValuesEl) {
		var	endDate = new Date(dateValuesEl.dataset.endDate),
  			_minute = 60000,
  			_hour = 3600000,
  			_day = 86400000,
  			timer,
  			daysEl = dateValuesEl.querySelector('.days b'),
  			hoursEl = dateValuesEl.querySelector('.hours b'),
  			minutesEl = dateValuesEl.querySelector('.minutes b');

	  function showRemaining() {
	    var now = new Date(),
	    		timeRemaining = endDate - now;

	    if (timeRemaining < 0) {
	      clearInterval(timer);
	      daysEl.innerHTML = '00';
		    hoursEl.innerHTML = '00';
		    minutesEl.innerHTML = '00';
	      return;
	    }

	    var days = Math.floor(timeRemaining / _day).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}),
	    		hours = Math.floor((timeRemaining % _day) / _hour).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}),
	    		minutes = Math.floor((timeRemaining % _hour) / _minute).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});

	    // if current countdown vals don't equal current, update it
	    if (daysEl.innerHTML !== days) {
		    daysEl.innerHTML = days;
	    }
	    if (hoursEl.innerHTML !== hours) {
		    hoursEl.innerHTML = hours;
	    }
	    if (minutesEl.innerHTML !== minutes) {
		    minutesEl.innerHTML = minutes;
	    }
	  }

	  timer = setInterval(showRemaining, 1000);
	  
	  //TODO: set top position of sticky bar
	}
}