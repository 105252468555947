export default function countdownStrip() {
	var modalContainer = $('.modal-container');

	if ($(modalContainer).length > 0) {
		modalContainer.each(function() {
			var thisImg = $(this).closest('.image-text-col-row').find('.has-video'),
					thisContainer = $(this),
					thisVideo = thisContainer.find('iframe');

	    // click image to open modal
	    thisImg.on('click',function(){
	    	// if video src is empty, populate it again
	      if (thisVideo.attr('src') == ''){
	        var datasrc = thisVideo.attr('data-src');
	        thisVideo.attr('src', datasrc);
	      }
	      $(thisContainer).addClass('active');
	    });

	    // click anywhere in modal (not iframe) to close modal
	    thisContainer.on('click',function(e){
	      // turn off the youtubes
	      // https://developers.google.com/youtube/iframe_api_reference
	      thisContainer.removeClass('active');
	      var src = thisVideo.attr('src');
	      thisVideo.attr('src','').attr('data-src', src);
	    });
		});
	}
};